<template>
  <div id="nav" v-if="!$route.meta.noNav" class="no-print">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  <router-view />
</template>

<style lang="less">

    @media print {
        .no-print {
            display: none;
        }
    }

:root {
    --spotify-green: #1DB954;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

    input {
        font-size: 1em;
        height: 2em;
        border-radius: .25em;
        box-shadow: none;
    }

        input[type=checkbox] {
            position: relative;
            width: 1.5em;
            height: 1.5em;
            top: .25em;
        }

        input[type=color] {
            appearance: none;
            border: 1px solid black;
        }
</style>
