
    import { defineComponent } from "vue";
    import BingoCard from "@/components/BingoCard.vue";

    interface cardOptions {
        cardAmount: number,
        cardTitle: string,
        cardStyle: Record<string, unknown>,
        columnCount: number
    }

    export default defineComponent({
        name: "HelloWorld",
        async mounted() {
            try {
                const response = await this.$api.user.getPlaylists();
                this.playlists = response.items;
            } catch (e) {
                alert(`An error has occured while fetching your playlists (${e.response.status})`);
            }finally {
                if (this.hasConnection) {
                    this.currentStage = 1;
                }
                const storedStyle = localStorage.getItem("cardStyle");
                const storedTitle = localStorage.getItem("cardTitle");
                if (storedStyle !== undefined && storedStyle !== null) {
                    this.cardOptions.cardStyle = JSON.parse(storedStyle);
                }
                if (storedTitle !== undefined && storedTitle !== null) {
                    this.cardOptions.cardTitle = storedTitle;
                }
            }
        },
        watch: {
            "cardOptions": {
                handler() {
                    localStorage.setItem("cardStyle", JSON.stringify(this.cardOptions.cardStyle));
                    localStorage.setItem("cardTitle", this.cardOptions.cardTitle);
                },
                deep: true
            }
        },
        methods: {
            async getPlaylistSongs(id: string) {
                try {
                    const response = await this.$api.playlists.getPlaylist(id, { fields: "tracks.items(track(name,artists(name),album(images(url))))" });
                    this.songs = (response.tracks.items);
                    this.songs.forEach(song => song.track.name_on_card = song.track.name)
                    this.currentStage = 2;
                } catch (e) {
                    this.songs = [];
                    if (e.response.status === 404) {
                        alert("Playlist was not found.");
                    } else {
                        alert(`An error has occured while opening the playlist (${e.response.status})`);
                    }
                }
            },
            getPlaylistSongsFromUrl() {
                const url = new URL(this.playlistUrl);
                if (url !== undefined && url.pathname != undefined) {
                    this.getPlaylistSongs(url.pathname.replace("/playlist/", ""));
                }
            },
            randomSubset(input: Array<any>) {
                // Get 25 random elements from the array
                let pool = input.slice(0, input.length);
                let final = [];
                const spotsOnCard = this.cardOptions.columnCount * this.cardOptions.columnCount; // Default is 5*5 = 25
                for (var i = 0; i < Math.min(spotsOnCard, input.length); i++) {
                    const elementId = Math.floor(Math.random() * pool.length);
                    const element = pool[elementId];
                    final.push(element.track.name_on_card);
                    pool.splice(elementId, 1);
                }
                return final;
            },
            changeCardBaseSetting(event: any) {
                switch (event.target.value) {
                    case "song-and-artist":
                        this.songs.forEach(song => song.track.name_on_card = song.track.name + "\n\n" + song.track.artists.map((artist: any) => artist.name).join(", "));
                        break;
                    case "song-only":
                    default:
                        this.songs.forEach(song => song.track.name_on_card = song.track.name);
                        break;
                }
            }
        },
        computed: {
            hasConnection() {
                return sessionStorage.getItem("authToken") !== undefined && sessionStorage.getItem("authToken") !== null;
            },
            spotifyAuthScope(): Array<string> {
                return [
                    this.privatePlaylists ? "playlist-read-private" : "",
                    this.collabPlaylists ? "playlist-read-collaborative" : "",
                    this.accessPlayback ? "user-modify-playback-state" : ""
                ]
            },
            stages() {
                return [
                    { title: "Connect with Spotify", active: true },
                    { title: "Select playlist", active: (this as any).hasConnection },
                    { title: "Set names", active: (this as any).songs.length > 0 },
                    { title: "Have fun!", active: (this as any).songs.length > 0 }
                ]
            },
            // Function that returns only unique cards
            bingoCardItems(): Array<any> {
                let cards: any[] = [];
                let rows: any[] = [];

                const th = (this as any);
                let tries = 0;

                // Generate all the cards
                for (let i = 0; i < th.cardOptions.cardAmount; i += 1) {
                    // Only try for a certain amount of times to find a unique card
                    if (tries > 15) {
                        i += 1;
                        tries = 0;
                    }

                    let isUnique = true; // Whether this card is unique, thus does not have any rows/columns or full card in common with another card
                    let cardRows = [];   // All the rows and columns present in this card

                    // Generate candidate card
                    const card = this.randomSubset(th.songs);

                    // Turn card into matrix, so we can transpose it and look at the columns
                    const cardMatrix = card.reduce((rows, key, index) => (index % th.cardOptions.columnCount == 0 ? rows.push([key])
                        : rows[rows.length - 1].push(key)) && rows, []);
                    const cardTranspose = cardMatrix[0].map((_: any, column: number) => cardMatrix.map((row: any) => row[column])).flat();

                    // Now, compare all rows and columns to see if they are already in another card
                    for (let j = 0; j < card.length; j += th.cardOptions.columnCount) {
                        const row = card.slice(j, j + th.cardOptions.columnCount);
                        const column = cardTranspose.slice(j, j + th.cardOptions.columnCount);

                        // Check row
                        if (rows.some(existing_row => existing_row.every((song: any) => row.includes(song)))) {
                            isUnique = false;
                            break;
                        // Check column
                        } if (rows.some(existing_row => existing_row.every((song: any) => column.includes(song)))) {
                            isUnique = false;
                            break;
                        // Not in common with another card, store this column and row
                        } else {
                            cardRows.push(row);
                            cardRows.push(column);
                        }
                    }
                    // Final check: is this card the same as any other card
                    isUnique = isUnique && !cards.some(existing_card => existing_card.every((song: any) => card.includes(song)));
                    if (isUnique) {
                        rows = rows.concat(cardRows);
                        cards.push(card);
                    } else {
                        i -= 1;
                        tries += 1;
                    }
                }
                return cards;
            }
        },
        data(): { songs: Array<any>, playlists: Array<any>, cardOptions: cardOptions, currentStage: number, privatePlaylists: boolean, collabPlaylists: boolean, accessPlayback: boolean, playlistUrl: string } {
            return {
                songs: [],
                playlists: [],
                cardOptions: {
                    cardAmount: 3,
                    cardTitle: "Bingooooooo 2021",
                    cardStyle: { color: "#ffffff" },
                    columnCount: 5
                },
                currentStage: 0,
                privatePlaylists: false,
                collabPlaylists: false,
                accessPlayback: false,
                playlistUrl: ""
            }
        },
        components: {
            BingoCard
        }
    });
