
    import { defineComponent } from "vue";

    export default defineComponent({
        name: "BingoCard",
        props: {
            songs: Array, // Subset of songs to display on this card
            cardTitle: String, // Text above the card
            cardStyle: Object, // Style to add to the card
            showExport: Boolean, // Whether the link to open the standalone card should be displayed
            columnCount: Number // Amount of columns and rows to generate
        },
        methods: {
            generateUrl(): string {
                return `/card?t=${encodeURIComponent(this.cardTitle as string)}&c=${this.columnCount}&l=${encodeURIComponent(JSON.stringify(this.songs))}&s=${encodeURIComponent(JSON.stringify(this.cardStyle))}`;
            }
        },
        created() {
            if (!this.showExport) {
                document.title = this.cardTitle ?? "Bingo";
            }
        },
        computed: {
            whatsappLink() {
                return `whatsapp://send?text=${encodeURIComponent(window.location.href)}`;
            },
            modifiedStyle() {
                let style = this.cardStyle ?? { };
                style.gridTemplateColumns = `repeat(${this.columnCount ?? 0}, 1fr)`;
                style.gridTemplateRows = `min-content repeat(${this.columnCount ?? 0}, 1fr)`;
                return style;
            }
        }
    });
