
import { defineComponent } from "vue";
import Generator from "@/components/Generator.vue"; // @ is an alias to /src

export default defineComponent({
  name: "Home",
  components: {
      Generator,
  },
});
