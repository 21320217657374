import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import apiWrapper from "./scripts/api";

declare module "@vue/runtime-core" {
    export interface ComponentCustomProperties {
        $api: typeof apiWrapper;
    }
}

const app = createApp(App);
app.config.globalProperties.$api = apiWrapper;
app.use(router).mount("#app");
