import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4794004a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showExport)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "share no-print",
          href: _ctx.generateUrl(),
          target: "_blank"
        }, "Delen", 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.songs.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "bingo-card",
          style: _normalizeStyle(_ctx.modifiedStyle)
        }, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.cardTitle), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.songs.slice(0,_ctx.columnCount * _ctx.columnCount)), (song) => {
            return (_openBlock(), _createElementBlock("pre", {
              class: "bingo-item",
              key: song,
              onClick: _cache[0] || (_cache[0] = (ev) => ev.target.style.backgroundColor = 'grey')
            }, "                " + _toDisplayString(song) + "\r\n            ", 1))
          }), 128))
        ], 4))
      : _createCommentVNode("", true),
    (!_ctx.showExport)
      ? (_openBlock(), _createElementBlock("a", {
          key: 2,
          href: _ctx.whatsappLink,
          target: "_blank",
          class: "no-print"
        }, "Deel via whatsapp", 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}