import { createRouter, createWebHistory, RouteRecordRaw, NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
    {
        path: "/card",
        name: "Card",
        component: () =>
            import(/* webpackChunkName: "about" */ "../components/BingoCard.vue"),
        props: route => ({
            songs: route.query.l ? JSON.parse(route.query.l as string) : [],
            cardStyle: route.query.s ? JSON.parse(route.query.s as string) : {},
            // function mode, more about it below
            cardTitle: route.query.t,
            columnCount: isNaN(parseInt(route.query.c as string)) ? 5 : parseInt(route.query.c as string)
        }),
        meta: {
            noNav: true
        }
    },
    {
        path: "/callback",
        component: Home,
        beforeEnter: function (
            to: RouteLocationNormalized,
            from: RouteLocationNormalized,
            next: NavigationGuardNext
        ) {
            if (to.hash.length > 0) {
                const hash = to.hash.substring(1); // Remove # from string
                router.replace(`/callback?${hash}`);
                return;
            } else {
                if ("access_token" in to.query) {
                    sessionStorage.setItem("authToken", to.query["access_token"] as string);
                }
            }
            router.replace("/");
        }
    }
];

const router = createRouter({
    //history: createWebHashHistory(),
    history: createWebHistory(),
    routes,
});

export default router;
