import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import axios from "axios"

const api = axios.create({
    baseURL: `https://api.spotify.com/v1/`,
    headers: { "Content-Type": "application/json" }
})

class PlaylistsResponse {
    constructor(readonly href: string, readonly items: Array<any>, readonly limit: number, readonly next: string, readonly offset: number, readonly previous: string, readonly total: number) { }
}

class PlaylistResponse {
    constructor(readonly collaborative: boolean, readonly description: string | null, readonly href: string, readonly id: string, readonly images: Array<any>, readonly name: string, readonly tracks: PlaylistTracksEntry, readonly uri: string) { }
}

class PlaylistTracksEntry {
    constructor(readonly href: string, readonly items: Array<any>, readonly limit: number, readonly next: string, readonly offset: number, readonly previous: string, readonly total: number) { }
}

api.interceptors.request.use(
    async (request : AxiosRequestConfig) => {
        // Retrieve id-token from session storage
        const authToken = sessionStorage.getItem("authToken");

        // If no token in session storage, redirect to login
        if (authToken === null) {
            return Promise.reject(request);
        }

        // Add bearer token header
        if (request.headers != undefined) {
            request.headers["authorization"] = `Bearer ${authToken}`;
        }

        return request;
    },
    error => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    async (response: AxiosResponse) => {
        return Promise.resolve(response)
    },
    (error: AxiosError) => {
        // Retrieve id-token from session storage
        const authToken = sessionStorage.getItem("authToken");
        if (error.response?.status === 401 || error.response?.status == 400) {
            if (authToken !== null || authToken === undefined || authToken === "") {
                sessionStorage.removeItem("authToken");
                window.location.reload();
            }
        }

        return Promise.reject(error);
    }
);

async function getPlaylists(): Promise<PlaylistsResponse> {
    const response = await api.get(`/me/playlists`);
    return response.data;
}

async function getPlaylist(playlist_id: string, q: { fields: string | undefined }): Promise<PlaylistResponse> {
    const response = await api.get(`/playlists/${playlist_id}`, {params: q});
    return response.data;
}

function getSpotifyAuthLink(permissions: Array<string> | undefined): string {
    const client_id = "7b747381d8cf43e9ac0f9e9ac255b4e0";
    const state = 16;
    const response_type = "token";
    const scope = permissions ? permissions.join(" ") : "";
    const scopeQuery = permissions ? `&scope=${scope}` : "";
    const redirect_uri = `${window.location.protocol}//${window.location.host}/callback`;
    return `https://accounts.spotify.com/authorize?redirect_uri=${redirect_uri}&client_id=${client_id}&state=${state}&response_type=${response_type}${scopeQuery}`;
}

export default {
    user: {
        getPlaylists
    },
    playlists: {
        getPlaylist
    },
    getSpotifyAuthLink
}